<template>
  <div class="container remianPage">
    <NavBar class="navbar" />
    <div class="box">
      <Empty v-if="!list.length"></Empty>
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        style="height: 100%"
        @load="onLoad"
      >
        <van-cell v-for="(item, index) in list" :key="index">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #title>
            <div class="title-box">
              <span class="time">兑换时间：{{ item.redeemTime }}</span>
              <span class="cardName">{{
                item.durationOfConvertibility | durationFilter
              }}</span>
            </div>
          </template>
          <template #label>
            <div class="label-box">
              <span class="time">到期时间：{{ item.expirationDate }}</span>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Empty from '@/components/Empty.vue'
import { redeemedRecord } from '@/api/goods.js'
import { calcHourMin } from '@/utils/index.js'
export default {
  components: {
    NavBar,
    Empty
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      list: []
    }
  },
  filters: {
    durationFilter(val) {
      const map = {
        0: '1小时',
        1: '2小时',
        2: '5小时',
        3: '10小时'
      }
      return map[val] || '-'
    }
  },
  created() {
    this.page.pageNum = 1
    this.getList()
  },
  methods: {
    calcHourMin,
    onLoad() {
      this.page.pageNum++
      this.getList()
    },
    async getList() {
      const { pageNum, pageSize } = this.page
      const params = {
        pageNum,
        pageSize
      }
      const res = await redeemedRecord(params)
      if (res.status === 200) {
        const records = res.data.list
        this.loading = false
        this.page.total = res.data.totalItems
        if (records == null || records.length === 0) {
          this.finished = true
          return
        }
        this.list = this.list.concat(records)
        if (this.list.length >= this.page.total) {
          this.finished = true
        }
      } else {
        this.$toast.error('请求数据失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
// .navbar {
//   position: fixed;
//   width: 100%;
//   top: 0;
//   left: 0;
// }
.remianPage {
  // padding-top: 44px;
  box-sizing: border-box;
}
.desc {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 10px;
  font-size: 12px;
  color: @text-color-2;
}
.box {
  width: 343px;
  margin: auto;
  height: calc(100vh - 44px - 88px);
  overflow: auto;
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 10px;
    .time {
      font-size: 13px;
      font-weight: 500;
      color: #aaaaaa;
      line-height: 20px;
    }
    .cardName {
      font-size: 14px;
      color: @text-color-1;
      font-weight: 500;
    }
  }
  .label-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time {
      font-size: 13px;
      color: @text-color-2;
    }
  }
}
</style>
